<template>
  <PageLayout>
    <template #header>
      <k-title>{{ $tc('report.title', 1) }}</k-title>
    </template>
    <template #flow-actions>
      <v-row no-gutters>
        <VSelect
          v-model="selectedReport"
          :items="items"
          item-text="text"
          item-value="value"
        />
      </v-row>
    </template>
    <template #default>
      <keep-alive>
        <!-- @TODO: make chart size responsive -- highcharts doesn't accept percentages -->
        <component
          :is="selectedReport"
          :options="mergedChartOptions"
          :employer-id.sync="employerId"
          :partition-type.sync="reportType"
        />
      </keep-alive>
    </template>
  </PageLayout>
</template>

<script>
import eventBus from '@/application/eventBus.ts';
import KTitle from '@/components/layout/KTitle.vue';
import PageLayout from '@/components/layout/PageLayout.vue';
import OpenShiftsVsAvailableCandidates from '@/modules/report/components/OpenShiftsVsAvailableCandidates.vue';
import PartitionOpenShift from '@/modules/report/components/PartitionOpenShift.vue';
import ShiftStatus from '@/modules/report/components/ShiftStatus.vue';
import ShiftUrgencyBenchmark from '@/modules/report/components/ShiftUrgencyBenchmark.vue';
import mergedChartOptions from '@/modules/report/mixins/mergedChartOptions.vue';

export default {
  name: 'ReportDetails',
  components: {
    KTitle,
    PageLayout,
    OpenShiftsVsAvailableCandidates,
    PartitionOpenShift,
    ShiftStatus,
    ShiftUrgencyBenchmark,
  },
  mixins: [mergedChartOptions],
  data() {
    return {
      selectedReport: null,
      chartOptions: {
        chart: {
          width: 800,
          height: 500,
        },
      },
      reportName: '',
      reportType: '',
      employerId: null,
    };
  },
  computed: {
    i18nReports() {
      return {
        'ShiftStatus': 'shiftStatus',
        'OpenShiftsVsAvailableCandidates': 'openShiftsVsAvailableCandidates',
        'PartitionOpenShift': 'partitionOpenShift',
        'ShiftUrgencyBenchmark': 'shiftUrgencyBenchmark',
      };
    },
    items() {
      return Object.entries(this.i18nReports).map(([key, value]) => ({
        text: this.$t(`report.${value}.title`),
        value: key,
      }));
    },
  },
  created() {
    const {
      reportName,
      reportType,
      employerId,
    } = this.$route.query;
    Object.assign(this, {
      reportName,
      reportType,
      ...employerId && { employerId: parseInt(employerId) },
      selectedReport: reportName ? reportName : Object.keys(this.i18nReports)[0],
    });

    eventBus.$emit('setBreadcrumbs', [{
      exact: true,
      text: this.$tc('report.title', 1),
    }]);
  },
};
</script>
