<template>
  <KHighcharts
    v-bind="$attrs"
    :options="mergedChartOptions"
    v-on="$listeners"
  />
</template>

<script>
import KHighcharts from '@/modules/report/base/KHighcharts.vue';
import mergedChartOptions from '@/modules/report/mixins/mergedChartOptions.vue';

export default {
  name: 'StackingPercentageChart',
  components: { KHighcharts },
  mixins: [mergedChartOptions],
  inheritAttrs: false,
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'column',
        },
        title: false,
        subtitle: false,
        yAxis: {
          min: 0,
        },
        tooltip: {
          pointFormat: '<span style="color:{series.color}">{series.name}</span>: <strong>{point.y}</strong> ({point.percentage:.1f}%)<br/>',
          shared: true,
        },
        plotOptions: {
          column: {
            stacking: 'percent',
          },
        },
      },
    };
  },
};
</script>
