<template>
  <ReportSheet
    :title="$t(`report.partitionOpenShift.${partitionType}.title`)"
    language-prefix="report.fields"
  >
    <template #filterArea>
      <v-col class="flex-grow-0 mr-3">
        <KRadioGroup
          v-model="partitioning"
          :items="partitionOptions"
          :style="{ minWidth: '240px' }"
          column
          field="partitionBy.title"
          grid="col-sm-6 pr-2"
          hide-details
          radio-class="mb-1"
          radio-group-class="pa-0 ma-0"
        />
      </v-col>
      <v-col class="flex-grow-0 mr-3">
        <KRadioGroup
          :items="partitionTypes"
          :value="partitionType"
          :style="{ minWidth: '240px' }"
          column
          field="partitionBy.titleSub"
          grid="col-sm-6 pr-2"
          hide-details
          radio-class="mb-1"
          radio-group-class="pa-0 ma-0"
          @change="updatePartitionType"
        />
      </v-col>
      <v-col class="flex-grow-0 mr-3">
        <EmployerAutocomplete
          :return-object="false"
          :style="{ minWidth: '160px' }"
          :value="employerId"
          field="employerId"
          hide-details
          @change="updateEmployerId"
        />
      </v-col>
    </template>

    <v-row
      v-if="isLoading"
      align="center"
      justify="space-around"
    >
      <PieSkeleton
        :max-height="500"
        fill="#CCC"
      />
    </v-row>

    <PieChart
      v-else
      v-bind="$attrs"
      :max-width="800"
      :options="mergedChartOptions"
      v-on="$listeners"
    />

    <template #tableArea>
      <div class="datatable">
        <VSkeletonLoader
          v-if="isLoading"
          type="table-thead, table-tbody, table-tfoot"
        />
        <VDataTable
          v-else
          :headers="headers"
          :items="tableData"
          :loading="isLoading"
          item-key="name"
        />
      </div>
    </template>
  </ReportSheet>
</template>

<script>
import PieSkeleton from '@/application/assets/PieSkeleton.vue';
import { shiftStatus } from '@/application/enums/shiftStatus.js';
import KRadioGroup from '@/components/crud/fields/KRadioGroup.vue';
import EmployerAutocomplete from '@/modules/employer/components/EmployerAutocomplete.vue';
import { show } from '@/modules/report/api/openDistribution.js';
import PieChart from '@/modules/report/charts/PieChart.vue';
import getWidgetData from '@/modules/report/mixins/getWidgetData.vue';
import mergedChartOptions from '@/modules/report/mixins/mergedChartOptions.vue';
import PartitionOpenShiftData from '@/modules/report/mixins/PartitionOpenShiftData.vue';
import ReportSheet from '@/modules/report/template/ReportSheet.vue';

export default {
  name: 'PartitionOpenShift',
  components: {
    KRadioGroup,
    EmployerAutocomplete,
    ReportSheet,
    PieChart,
    PieSkeleton,
  },
  mixins: [getWidgetData, mergedChartOptions, PartitionOpenShiftData],
  inheritAttrs: false,
  props: {
    partitionType: {
      type: String,
      default: 'employers',
      validator(value) {
        return ['employers', 'positions', 'terms'].includes(value);
      },
    },
    employerId: {
      type: Number,
    },
  },
  data() {
    return {
      partitioning: 0,
      isLoading: false,
    };
  },
  computed: {
    chartOptions() {
      return {
        series: this.chartData,
      };
    },
    tableData() {
      if (this.isLoading || !this.widgetData) return [];
      const total = this.chartOptions.series.reduce((accumulator, currentValue) => {
        accumulator += currentValue.data.reduce((accumulator, currentValue) => {
          accumulator += currentValue.y;
          return accumulator;
        }, 0);
        return accumulator;
      }, 0);
      return this.chartOptions.series.flatMap((serie) => serie.data.map(item => ({
        ...item,
        percentage: `${(item.y / total * 100).toFixed(2)}%`,
      }),
      ));
    },
    partitionOptions() {
      return [
        {
          text: this.$t('report.fields.partitionBy.allEmployers'),
          value: 0,
        }, {
          text: this.$t('report.fields.partitionBy.myEmployers'),
          value: 1,
        }];
    },
    partitionTypes() {
      return [
        {
          text: this.$t('report.fields.partitionBy.employers'),
          value: 'employers',
        }, {
          text: this.$t('report.fields.partitionBy.position'),
          value: 'positions',
        }];
    },
    headers() {
      return [
        {
          text: this.$t('report.partitionOpenShift.table.employer'),
          align: 'start',
          value: 'name',
        },
        {
          text: this.$t('report.partitionOpenShift.table.amount'),
          align: 'right',
          value: 'y',
        },
        {
          text: this.$t('report.partitionOpenShift.table.percentage'),
          align: 'right',
          value: 'percentage',
        },
      ];
    },
  },
  watch: {
    partitioning() {
      this.getWidgetData();
    },
    partitionType() {
      this.getWidgetData();
    },
    employerId: {
      handler() {
        this.getWidgetData();
      },
      immediate: true,
    },
  },
  methods: {
    async getWidgetData() {
      // @TODO: implement failed component?
      if (!this.partitionType) return;

      this.isLoading = true;
      const { data: { data } } = await show({
        type: this.partitionType,
        ...this.employerId && { employerId: this.employerId },
        onlyMyEmployers: this.partitioning,
      });
      this.widgetData = data.map(item => ({
        ...item,
        params: { partitionType: this.partitionType, ...!!item.employerId && { employerId: item.employerId } },
        ...this.partitionType !== 'employers' && {
          query: {
            ...!!this.employerId && { employerId: this.employerId },
            shiftStatuses: [shiftStatus.NEW, shiftStatus.INVITED, shiftStatus.PROPOSED],
          },
        },
      }),
      );
      this.isLoading = false;
    },
    updatePartitionType(to) {
      this.$emit('update:partitionType', to);
    },
    updateEmployerId(to) {
      this.$emit('update:employerId', to);
    },
  },
};
</script>

<style lang="scss" scoped>
.datatable {
  :deep(.v-skeleton-loader__table-thead) {
    background: transparent;
  }

  :deep(.v-skeleton-loader__table-tbody) {
    background: transparent;
  }

  :deep(.v-skeleton-loader__table-tfoot) {
    background: transparent;
  }

  :deep(.v-data-table) {
    background: transparent;
  }
}
</style>
