import { get } from '@/application/api/implementations/app';

/**
 * @param {Object} params - params to pass on to axios instance
 * @param {string} params.type - required type. Either positions / employers
 * @param {number} [params.employerId] - optional select specific employer
 * @param {boolean} [params.onlyMyEmployers] - optionally show only employers responsible
 */
export const show = (params) => {
  if ('type' in params) {
    return get('report/shift/open-distribution', { params });
  }
  throw 'type is required for get request open-distribution!';
};
