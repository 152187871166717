<script>
export default {
  name: 'PartitionOpenShiftData',
  computed: {
    chartData() {
      if (!this.widgetData) return [];
      return [{
        data: this.widgetData.map(({
          name,
          count,
          query,
          params,
        }) => ({
          name,
          y: count,
          events: {
            click: () => {
              const routeMap = {
                'employers': 'employer.show',
                'positions': 'shift.index',
              };
              this.$router.push({
                name: routeMap[this.partitionType],
                query,
                params,
              });
            },
          },
        })),
      }];
    },
  },
};
</script>
