<template>
  <KHighcharts
    v-bind="$attrs"
    :options="mergedChartOptions"
    v-on="$listeners"
  />
</template>

<script>
import KHighcharts from '@/modules/report/base/KHighcharts.vue';
import mergedChartOptions from '@/modules/report/mixins/mergedChartOptions.vue';

export default {
  name: 'StackingColumnChart',
  components: { KHighcharts },
  mixins: [mergedChartOptions],
  inheritAttrs: false,
  data() {
    return {
      chartOptions: {
        subtitle: false,
        chart: {
          type: 'column',
        },
        xAxis: {
          labels: {
            rotation: 75,
          },
        },
        plotOptions: {
          column: {
            stacking: 'normal',
          },
        },
      },
    };
  },
};
</script>
