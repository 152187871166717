<script>
export default {
  name: 'OpenShiftsVsAvailableCandidatesData',
  computed: {
    chartData() {
      if (!this.widgetData) return [];
      return [{
        name: this.$t('report.openShiftsVsAvailableCandidates.availableCandidates'),
        type: 'column',
        data: this.widgetData.map(({ candidates }) => ({
          y: candidates,
          events: {
            click: () => {
              this.$router.push({
                name: 'candidate.index',
              });
            },
          },
        })),
        dataLabels: {
          enabled: true,
        },
      },
      {
        name: this.$t('report.openShiftsVsAvailableCandidates.openShifts'),
        data: this.widgetData.map(({
          shifts,
          query,
        }) => ({
          y: shifts,
          events: {
            click: () => {
              this.$router.push({
                name: 'shift.index',
                query,
              });
            },
          },
        }),
        ),
        dataLabels: {
          enabled: true,
        },
      },
      ];
    },
  },
};
</script>
