<template>
  <ReportSheet
    :title="$t(`report.openShiftsVsAvailableCandidates.title`)"
    language-prefix="report.fields"
  >
    <template #filterArea>
      <v-col class="flex-grow-0 mr-3">
        <KRadioGroup
          v-model="partitioning"
          :items="partitionOptions"
          :style="{ minWidth: '240px' }"
          column
          field="partitionBy.title"
          grid="col-sm-6 pr-2"
          hide-details
          radio-class="mb-1"
          radio-group-class="pa-0 ma-0"
        />
      </v-col>
      <v-col class="flex-grow-0 mr-3">
        <KDateField
          v-model="fromDate"
          field="dateFrom"
          hide-details
        />
      </v-col>
      <v-col class="flex-grow-0 mr-3">
        <KDateField
          v-model="toDate"
          field="dateTo"
          hide-details
        />
      </v-col>
      <v-col class="flex-grow-0 mr-3">
        <EmployerAutocomplete
          :return-object="false"
          :style="{ minWidth: '160px' }"
          :value="employerId"
          field="employerId"
          hide-details
          @change="updateEmployerId"
        />
      </v-col>
    </template>

    <v-row
      v-if="isLoading"
      align="center"
      justify="space-around"
    >
      <GraphSkeleton
        :max-height="500"
        fill="#CCC"
      />
    </v-row>

    <LineColumnChart
      v-else
      v-bind="$attrs"
      :max-width="800"
      :options="mergedChartOptions"
      v-on="$listeners"
    />

    <template #tableArea>
      <div class="datatable">
        <VSkeletonLoader
          v-if="isLoading"
          type="table-thead, table-tbody, table-tfoot"
        />
        <VDataTable
          v-else
          :headers="headers"
          :items="tableData"
          :loading="isLoading"
          item-key="name"
        />
      </div>
    </template>
  </ReportSheet>
</template>

<script>
import GraphSkeleton from '@/application/assets/GraphSkeleton.vue';
import { shiftStatus } from '@/application/enums/shiftStatus.js';
import KDateField from '@/components/crud/fields/KDateField.vue';
import KRadioGroup from '@/components/crud/fields/KRadioGroup.vue';
import EmployerAutocomplete from '@/modules/employer/components/EmployerAutocomplete.vue';
import { show } from '@/modules/report/api/candidateAvailability.js';
import LineColumnChart from '@/modules/report/charts/LineColumnChart.vue';
import getWidgetData from '@/modules/report/mixins/getWidgetData.vue';
import mergedChartOptions from '@/modules/report/mixins/mergedChartOptions.vue';
import OpenShiftsVsAvailableCandidatesData from '@/modules/report/mixins/OpenShiftsVsAvailableCandidatesData.vue';
import ReportSheet from '@/modules/report/template/ReportSheet.vue';
import dayjs from '@/plugins/dayjs';

export default {
  name: 'OpenShiftsVsAvailableCandidates',
  components: {
    KRadioGroup,
    EmployerAutocomplete,
    KDateField,
    ReportSheet,
    LineColumnChart,
    GraphSkeleton,
  },
  mixins: [getWidgetData, mergedChartOptions, OpenShiftsVsAvailableCandidatesData],
  inheritAttrs: false,
  props: {
    employerId: {
      type: Number,
    },
  },
  data() {
    return {
      fromDate: dayjs().format('YYYY-MM-DD'),
      toDate: dayjs().add(7, 'day').format('YYYY-MM-DD'),
      partitioning: 0,
      isLoading: false,
    };
  },
  computed: {
    chartOptions() {
      return {
        xAxis: {
          categories: this.widgetData.map(({ date }) => date),
        },
        yAxis: {
          title: {
            text: this.$t('report.openShiftsVsAvailableCandidates.amountADay'),
          },
        },
        series: this.chartData,
      };
    },
    tableData() {
      if (this.isLoading) return [];
      return this.widgetData;
    },
    partitionOptions() {
      return [
        {
          text: this.$t('report.fields.partitionBy.allEmployers'),
          value: 0,
        }, {
          text: this.$t('report.fields.partitionBy.myEmployers'),
          value: 1,
        }];
    },
    headers() {
      return [
        {
          text: this.$t('report.openShiftsVsAvailableCandidates.table.period'),
          align: 'start',
          value: 'date',
        },
        {
          text: this.$t('report.openShiftsVsAvailableCandidates.table.available'),
          align: 'right',
          value: 'candidates',
        },
        {
          text: this.$t('report.openShiftsVsAvailableCandidates.table.shifts'),
          align: 'right',
          value: 'shifts',
        },
      ];
    },
  },
  watch: {
    partitioning() {
      this.getWidgetData();
    },
    fromDate() {
      this.getWidgetData();
    },
    toDate() {
      this.getWidgetData();
    },
    employerId: {
      handler() {
        this.getWidgetData();
      },
      immediate: true,
    },
  },
  methods: {
    async getWidgetData() {
      // @TODO: implement failed component?
      this.isLoading = true;
      const from = this.fromDate || dayjs().format('YYYY-MM-DD');
      const to = this.toDate || dayjs().add(7, 'day').format('YYYY-MM-DD');
      const { data: { data } } = await show({
        from,
        to,
        ...this.employerId && { employerId: this.employerId },
        onlyMyEmployers: this.partitioning,
      });
      this.widgetData = data.map(item => ({
        ...item,
        params: { partitionType: this.partitionType, ...!!item.employerId && { employerId: item.employerId } },
        query: {
          ...!!this.employerId && { employerId: this.employerId },
          from: item.date,
          to: item.date,
          shiftStatuses: [shiftStatus.NEW, shiftStatus.INVITED, shiftStatus.PROPOSED],
        },
      }));
      this.isLoading = false;
    },
    updateEmployerId(to) {
      this.$emit('update:employerId', to);
    },
  },
};
</script>

<style lang="scss" scoped>
.datatable {
  :deep(.v-skeleton-loader__table-thead) {
    background: transparent;
  }

  :deep(.v-skeleton-loader__table-tbody) {
    background: transparent;
  }

  :deep(.v-skeleton-loader__table-tfoot) {
    background: transparent;
  }

  :deep(.v-data-table) {
    background: transparent;
  }
}
</style>
