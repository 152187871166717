<script>
import { shiftStatus } from '@/application/enums/shiftStatus';

export default {
  name: 'ShiftStatusData',
  computed: {
    chartData() {
      if (!this.widgetData) return [];
      return [{
        name: this.$t('report.shiftStatus.new'),
        color: this.theme.error,
        data: this.widgetData.map(({
          new: newShifts,
          query,
        }) => ({
          y: newShifts,
          events: {
            click: () => {
              this.$router.push({
                name: 'shift.index',
                query: {
                  shiftStatuses: [shiftStatus.NEW],
                  ...query,
                },
              });
            },
          },
        })),
      }, {
        name: this.$t('report.shiftStatus.invited'),
        color: this.theme.info,
        data: this.widgetData.map(({
          invited,
          query,
        }) => ({
          y: invited,
          events: {
            click: () => {
              this.$router.push({
                name: 'shift.index',
                query: {
                  shiftStatuses: [shiftStatus.INVITED],
                  ...query,
                },
              });
            },
          },
        })),
      }, {
        name: this.$t('report.shiftStatus.fulfilled'),
        color: this.theme.success,
        data: this.widgetData.map(({
          fulfilled,
          query,
        }) => ({
          y: fulfilled,
          events: {
            click: () => {
              this.$router.push({
                name: 'shift.index',
                query: {
                  shiftStatuses: [shiftStatus.FULFILLED],
                  ...query,
                },
              });
            },
          },
        })),
      }];
    },
  },
};
</script>
