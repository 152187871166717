<script>
import { shiftStatus } from '@/application/enums/shiftStatus';
import dayjs from '@/plugins/dayjs';

export default {
  name: 'ShiftUrgencyBenchmarkData',
  computed: {
    chartData() {
      if (!this.widgetData) return [];
      const { data: widgetData } = this.widgetData;
      return Object.keys(widgetData).map((period, index) => ({
        name: this.$t(`report.shiftUrgencyBenchmark.periods.${period}`),
        color: this.theme[['error', 'info', 'success'][index]],
        data: Object.keys(widgetData[period]).map((category) => ({
          y: widgetData[period][category],
          events: {
            click: () => {
              const {
                from,
                to,
                time,
              } = this.periodToDate(period);
              this.$router.push({
                name: 'shift.index',
                query: {
                  ...(!!this.employerId && category === 'currentEmployer') && { employerId: this.employerId },
                  shiftStatuses: [shiftStatus.NEW, shiftStatus.INVITED, shiftStatus.PROPOSED],
                  ...from && { from },
                  ...to && { to },
                  ...time && { time },
                },
              });
            },
          },
        }),
        ),
      }));
    },
  },
  methods: {
    periodToDate(period) {
      return {
        lessThanDay: {
          from: dayjs().format('YYYY-MM-DD'),
          to: dayjs().add(1440, 'minute').format('YYYY-MM-DD'),
          time: dayjs().format('HH:mm:ss'),
        },
        betweenOneAndTwoDays: {
          from: dayjs().add(1441, 'minute').format('YYYY-MM-DD'),
          to: dayjs().add(2880, 'minute').format('YYYY-MM-DD'),
          time: dayjs().format('HH:mm:ss'),
        },
        moreThanThreeDays: {
          from: dayjs().add(2881, 'minute').format('YYYY-MM-DD'),
          time: dayjs().format('HH:mm:ss'),
        },
      }[period];
    },
  },
};
</script>
