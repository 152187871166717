<template>
  <svg
    :height="skeletonHeight"
    :width="computedWidth"
    viewBox="0 0 410 315"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      v-bind="$attrs"
      d="M 205.00,3.00
           C 205.00,3.00 205.00,157.00 205.00,157.00
             205.00,157.00 359.00,157.00 359.00,157.00
             359.00,141.53 357.27,120.69 352.48,106.00
             339.50,66.18 310.95,32.09 272.00,15.43
             250.49,6.22 228.23,3.00 205.00,3.00 Z
           M 340.00,138.00
           C 340.00,138.00 224.00,138.00 224.00,138.00
             224.00,138.00 224.00,22.00 224.00,22.00
             284.27,23.32 337.27,78.47 340.00,138.00 Z
           M 339.00,177.00
           C 339.00,177.00 195.00,177.00 195.00,177.00
             195.00,177.00 186.60,175.40 186.60,175.40
             186.60,175.40 185.00,168.00 185.00,168.00
             185.00,168.00 185.00,141.00 185.00,141.00
             185.00,141.00 185.00,23.00 185.00,23.00
             156.88,23.62 125.54,37.74 104.00,55.26
             70.66,82.39 52.69,122.43 51.04,165.00
             50.86,169.57 51.43,170.89 51.83,175.00
             53.17,188.80 54.25,196.57 58.28,210.00
             62.82,225.12 69.50,237.12 78.50,250.00
             96.20,275.37 124.30,295.68 154.00,304.56
             167.17,308.50 181.32,310.13 195.00,310.91
             195.00,310.91 215.00,309.13 215.00,309.13
             247.58,304.61 277.14,289.87 299.96,266.00
             310.67,254.80 320.25,240.21 326.42,226.00
             333.10,210.66 338.85,193.93 339.00,177.00 Z"
    />
  </svg>
</template>

<script>
export default {
  // @TODO: would be awesome to create an animation on this if used more often
  name: 'PieSkeleton',
  inheritAttrs: false,
  props: {
    width: { type: [Number, String] },
    height: { type: [Number, String] },
    maxHeight: { type: Number },
  },
  data() {
    return {
      skeletonHeight: null,
    };
  },
  computed: {
    computedWidth() {
      if (!this.width && this.height) return (410 / 315) * this.height;
      return this.width;
    },
  },
  async mounted() {
    await this.$nextTick();
    const el = this.$parent.$el;
    const offsetWidth = Math.min(el.offsetWidth, el.scrollWidth);
    const skeletonHeight = Math.min(el.offsetHeight, el.scrollHeight, (offsetWidth / 600) * 400, this.maxHeight);
    this.skeletonHeight = this.height || Math.max(0, skeletonHeight);
  },
};
</script>
