import { get } from '@/application/api/implementations/app';

/**
 * @param {Object} params - params to pass on to axios instance
 * @param {string} params.period - periodic weekly or daily
 * @param {string} params.from - date from
 * @param {string} params.to - date to
 * @param {number} [params.employerId] - optional select specific employer
 * @param {boolean} [params.onlyMyEmployers] - optionally show only employers responsible
 */
export const show = (params) => {
  if ('period' in params && 'from' in params && 'to' in params) {
    return get('report/shift/status', { params });
  }
  throw 'period, from and to is required for get request status!';
};
