<template>
  <ReportSheet
    :title="$t(`report.shiftUrgencyBenchmark.title`)"
    language-prefix="report.fields"
  >
    <template #filterArea>
      <v-col class="flex-grow-0 mr-3">
        <KRadioGroup
          v-model="partitioning"
          :items="partitionOptions"
          :style="{ minWidth: '240px' }"
          column
          field="partitionBy.title"
          grid="col-sm-6 pr-2"
          hide-details
          radio-class="mb-1"
          radio-group-class="pa-0 ma-0"
        />
      </v-col>
      <v-col class="flex-grow-0 mr-3">
        <EmployerAutocomplete
          :return-object="false"
          :style="{ minWidth: '160px' }"
          :value="employerId"
          field="employerId"
          hide-details
          @change="updateEmployerId"
        />
      </v-col>
    </template>

    <v-row
      v-if="!employerId"
      align="center"
      justify="space-around"
    >
      {{ $t('report.shiftUrgencyBenchmark.selectEmployer') }}
    </v-row>

    <v-row
      v-else-if="employerId && isLoading"
      align="center"
      justify="space-around"
    >
      <GraphSkeleton
        :max-height="500"
        fill="#CCC"
      />
    </v-row>

    <StackingPercentageChart
      v-else
      v-bind="$attrs"
      :max-width="800"
      :options="mergedChartOptions"
      v-on="$listeners"
    />

    <template #tableArea>
      <div class="datatable">
        <VSkeletonLoader
          v-if="isLoading"
          type="table-thead, table-tbody, table-tfoot"
        />
        <VDataTable
          v-else
          :headers="headers"
          :items="tableData"
          :loading="isLoading"
          item-key="name"
        />
      </div>
    </template>
  </ReportSheet>
</template>

<script>
import GraphSkeleton from '@/application/assets/GraphSkeleton.vue';
import KRadioGroup from '@/components/crud/fields/KRadioGroup.vue';
import EmployerAutocomplete from '@/modules/employer/components/EmployerAutocomplete.vue';
import { show } from '@/modules/report/api/urgency.js';
import StackingPercentageChart from '@/modules/report/charts/StackingPercentageChart.vue';
import getWidgetData from '@/modules/report/mixins/getWidgetData.vue';
import mergedChartOptions from '@/modules/report/mixins/mergedChartOptions.vue';
import ShiftUrgencyBenchmarkData from '@/modules/report/mixins/ShiftUrgencyBenchmarkData.vue';
import ReportSheet from '@/modules/report/template/ReportSheet.vue';

export default {
  name: 'ShiftUrgencyBenchmark',
  components: {
    KRadioGroup,
    EmployerAutocomplete,
    ReportSheet,
    StackingPercentageChart,
    GraphSkeleton,
  },
  mixins: [getWidgetData, mergedChartOptions, ShiftUrgencyBenchmarkData],
  inheritAttrs: false,
  props: {
    employerId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      partitioning: 0,
      isLoading: false,
    };
  },
  computed: {
    theme() {
      const {
        themes,
        isDark,
      } = this.$vuetify.theme;
      return themes[isDark ? 'dark' : 'light'];
    },
    chartOptions() {
      const categories = !this.widgetData ? [] : Object.keys(this.widgetData?.data[Object.keys(this.widgetData?.data)[0]]);
      return {
        xAxis: {
          categories: !this.widgetData?.data ? [] : categories.map(category => this.$t(`report.shiftUrgencyBenchmark.${category}`)),
        },
        yAxis: {
          title: {
            text: this.$t('report.shiftUrgencyBenchmark.percentageShifts'),
          },
        },
        series: this.chartData,
      };
    },
    tableData() {
      if (this.isLoading || !this.widgetData) return [];
      return Object.keys(this.widgetData?.data).map((period) => ({
        periodic: this.$t(`report.shiftUrgencyBenchmark.periods.${period}`),
        ...this.widgetData?.data[period],
      }),
      );
    },
    partitionOptions() {
      return [
        {
          text: this.$t('report.fields.partitionBy.allEmployers'),
          value: 0,
        }, {
          text: this.$t('report.fields.partitionBy.myEmployers'),
          value: 1,
        }];
    },
    headers() {
      return [
        {
          text: this.$t('report.shiftUrgencyBenchmark.periodic'),
          align: 'start',
          value: 'periodic',
        },
        {
          text: this.$t('report.shiftUrgencyBenchmark.currentEmployer'),
          align: 'right',
          value: 'currentEmployer',
        },
        {
          text: this.$t('report.shiftUrgencyBenchmark.sector'),
          align: 'right',
          value: 'sector',
        },
        {
          text: this.$t('report.shiftUrgencyBenchmark.allEmployers'),
          align: 'right',
          value: 'allEmployers',
        },
      ];
    },
  },
  watch: {
    partitioning() {
      this.getWidgetData();
    },
    employerId: {
      handler() {
        this.getWidgetData();
      },
      immediate: true,
    },
  },
  methods: {
    async getWidgetData() {
      // @TODO: implement failed component?
      if (!this.employerId) return;
      this.isLoading = true;
      const { data: { data } } = await show({
        ...this.employerId && { employerId: this.employerId },
        onlyMyEmployers: this.partitioning,
      });
      this.widgetData = {
        data,
        query: {
          ...this.employerId && { employerId: this.employerId },
        },
      };
      this.isLoading = false;
    },
    updateEmployerId(to) {
      this.$emit('update:employerId', to);
    },
  },
};
</script>

<style lang="scss" scoped>
.datatable {
  :deep(.v-skeleton-loader__table-thead) {
    background: transparent;
  }

  :deep(.v-skeleton-loader__table-tbody) {
    background: transparent;
  }

  :deep(.v-skeleton-loader__table-tfoot) {
    background: transparent;
  }

  :deep(.v-data-table) {
    background: transparent;
  }
}
</style>
