<template>
  <svg
    :height="skeletonHeight"
    :width="computedWidth"
    viewBox="0 0 410 315"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      v-bind="$attrs"
      d="M 393.00,6.47
           C 393.00,6.47 330.00,26.00 330.00,26.00
             322.80,28.38 308.33,31.70 303.01,36.22
             303.01,36.22 285.58,56.00 285.58,56.00
             285.58,56.00 254.28,92.00 254.28,92.00
             251.28,95.45 238.76,110.79 235.96,112.19
             232.08,114.14 222.79,108.14 219.00,106.25
             219.00,106.25 183.00,87.75 183.00,87.75
             179.22,85.87 172.17,81.37 168.04,82.78
             164.95,83.83 162.52,87.49 160.63,90.00
             160.63,90.00 146.63,109.00 146.63,109.00
             146.63,109.00 109.63,159.00 109.63,159.00
             109.63,159.00 92.54,181.69 92.54,181.69
             88.98,184.95 78.94,185.44 74.00,186.13
             74.00,186.13 30.00,192.73 30.00,192.73
             24.63,193.50 10.75,193.72 9.33,200.04
             6.98,210.53 23.90,205.84 29.00,205.13
             29.00,205.13 79.00,197.73 79.00,197.73
             84.30,196.95 96.04,195.92 99.83,192.69
             99.83,192.69 116.37,171.00 116.37,171.00
             116.37,171.00 152.63,122.00 152.63,122.00
             152.63,122.00 165.87,104.00 165.87,104.00
             167.27,102.15 169.91,98.10 172.17,97.52
             174.73,96.86 183.31,101.90 186.00,103.25
             186.00,103.25 223.00,122.25 223.00,122.25
             228.21,124.85 235.36,129.98 241.00,126.40
             244.52,124.17 256.70,109.17 260.27,105.00
             260.27,105.00 293.87,66.00 293.87,66.00
             293.87,66.00 311.09,46.27 311.09,46.27
             315.13,42.81 325.63,40.15 331.00,38.58
             331.00,38.58 363.00,28.58 363.00,28.58
             363.00,28.58 394.00,18.65 394.00,18.65
             397.61,17.33 402.58,14.84 401.16,10.06
             399.96,6.03 396.35,6.12 393.00,6.47 Z
           M 358.00,66.00
           C 358.00,66.00 358.00,311.00 358.00,311.00
             358.00,311.00 404.00,311.00 404.00,311.00
             404.00,311.00 404.00,66.00 404.00,66.00
             404.00,66.00 358.00,66.00 358.00,66.00 Z
           M 288.00,311.00
           C 288.00,311.00 326.00,311.00 326.00,311.00
             328.30,311.00 332.41,311.38 333.98,309.40
             335.50,307.48 335.00,298.77 335.00,296.00
             335.00,296.00 335.00,261.00 335.00,261.00
             335.00,261.00 335.00,98.00 335.00,98.00
             335.00,95.70 335.38,91.59 333.40,90.02
             331.60,88.60 325.41,89.00 323.00,89.00
             323.00,89.00 297.00,89.00 297.00,89.00
             295.01,89.00 291.19,88.77 289.60,90.02
             287.10,92.00 288.00,103.61 288.00,107.00
             288.00,107.00 288.00,159.00 288.00,159.00
             288.00,159.00 288.00,219.00 288.00,219.00
             288.00,219.00 288.00,311.00 288.00,311.00 Z
           M 195.00,311.00
           C 195.00,311.00 195.00,159.00 195.00,159.00
             195.00,159.00 157.00,159.00 157.00,159.00
             157.00,159.00 149.74,159.99 149.74,159.99
             149.74,159.99 148.00,167.00 148.00,167.00
             148.00,167.00 148.00,192.00 148.00,192.00
             148.00,192.00 148.00,302.00 148.00,302.00
             148.00,302.00 149.60,309.98 149.60,309.98
             149.60,309.98 162.00,311.00 162.00,311.00
             162.00,311.00 195.00,311.00 195.00,311.00 Z
           M 265.00,311.00
           C 265.00,311.00 265.00,254.00 265.00,254.00
             265.00,254.00 265.00,191.00 265.00,191.00
             265.00,191.00 263.40,183.02 263.40,183.02
             263.40,183.02 253.00,182.00 253.00,182.00
             253.00,182.00 227.00,182.00 227.00,182.00
             227.00,182.00 219.60,183.02 219.60,183.02
             219.60,183.02 218.00,189.00 218.00,189.00
             218.00,189.00 218.00,210.00 218.00,210.00
             218.00,210.00 218.00,302.00 218.00,302.00
             218.00,302.00 219.60,309.98 219.60,309.98
             219.60,309.98 232.00,311.00 232.00,311.00
             232.00,311.00 265.00,311.00 265.00,311.00 Z
           M 79.00,311.00
           C 79.00,311.00 117.00,311.00 117.00,311.00
             118.99,311.00 122.81,311.23 124.40,309.98
             126.93,307.97 126.00,294.58 126.00,291.00
             126.00,291.00 126.00,227.00 126.00,227.00
             125.99,220.14 126.80,217.14 119.00,217.00
             119.00,217.00 93.00,217.00 93.00,217.00
             90.61,217.00 83.11,216.72 81.31,217.60
             78.85,218.81 79.05,221.65 79.00,224.00
             79.00,224.00 79.00,267.00 79.00,267.00
             79.00,267.00 79.00,311.00 79.00,311.00 Z
           M 9.00,229.00
           C 9.00,229.00 9.00,311.00 9.00,311.00
             9.00,311.00 47.00,311.00 47.00,311.00
             48.99,311.00 52.81,311.23 54.40,309.98
             56.90,308.00 56.00,296.39 56.00,293.00
             56.00,293.00 56.00,238.00 56.00,238.00
             56.00,235.70 56.38,231.59 54.40,230.02
             52.51,228.52 44.66,229.00 42.00,229.00
             42.00,229.00 9.00,229.00 9.00,229.00 Z"
    />
  </svg>
</template>

<script>
export default {
  // @TODO: would be awesome to create an animation on this if used more often
  name: 'GraphSkeleton',
  inheritAttrs: false,
  props: {
    width: { type: [Number, String] },
    height: { type: [Number, String] },
    maxHeight: { type: Number },
  },
  data() {
    return {
      skeletonHeight: null,
    };
  },
  computed: {
    computedWidth() {
      if (!this.width && this.height) return (410 / 315) * this.height;
      return this.width;
    },
  },
  async mounted() {
    await this.$nextTick();
    const el = this.$parent.$el;
    const offsetWidth = Math.min(el.offsetWidth, el.scrollWidth);
    const skeletonHeight = Math.min(el.offsetHeight, el.scrollHeight, (offsetWidth / 600) * 400, this.maxHeight);
    this.skeletonHeight = this.height || Math.max(0, skeletonHeight);
  },
};
</script>
