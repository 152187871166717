<script>
export default {
  name: 'GetWidgetData',
  data() {
    return {
      widgetData: null,
    };
  },
  created() {
    this.getWidgetData();
  },
};
</script>
