import { get } from '@/application/api/implementations/app';

/**
 * @param {Object} params - params to pass on to axios instance
 * @param {number} params.employerId - required specific employer
 */
export const show = (params) => {
  if ('employerId' in params) {
    return get('report/shift/urgency', { params });
  }
  throw 'Employer id is required for get request urgency!';
};
