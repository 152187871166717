<template>
  <k-field-group :language-prefix="languagePrefix">
    <v-sheet class="report-sheet pa-4 mb-4">
      <v-row
        align="center"
        no-gutters
      >
        <slot name="filterArea" />
      </v-row>
    </v-sheet>

    <v-sheet class="pa-4 mb-4 report-sheet">
      <slot />
    </v-sheet>

    <v-sheet class="pa-4 mb-4 report-sheet">
      <slot name="tableArea" />
    </v-sheet>
  </k-field-group>
</template>

<script>

import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';

export default {
  name: 'ReportSheet',
  components: { KFieldGroup },
  inheritAttrs: false,
  props: {
    languagePrefix: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.report-sheet {
  background:   rgba(255, 255, 255, 0.3);
  border-color: transparent !important;
}
</style>
