<template>
  <KHighcharts
    v-bind="$attrs"
    :options="mergedChartOptions"
    v-on="$listeners"
  />
</template>

<script>
import KHighcharts from '@/modules/report/base/KHighcharts.vue';
import mergedChartOptions from '@/modules/report/mixins/mergedChartOptions.vue';

export default {
  name: 'LineColumnChart',
  components: { KHighcharts },
  mixins: [mergedChartOptions],
  inheritAttrs: false,
  data() {
    return {
      chartOptions: {
        subtitle: false,
        xAxis: {
          labels: {
            rotation: 75,
          },

        },
        chart: {
          zoomType: 'xy',
        },
      },
    };
  },
};
</script>
